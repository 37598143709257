module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-KCSKH0Y164"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","name":"Nittany Cleaners - Residential Cleaning Service","short_name":"Nittany Cleaners","description":"Residential cleaning service offering one-time cleaning and cleaning on regular schedule. Get a free consultation and quote!","lang":"en","start_url":"/","background_color":"#E1F7FE","theme_color":"#004E5E","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e3a51b3797137eb4713714b7e06f83be"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
